export const PROJECT_NAME = 'Mupza';
export const BASE_URL = 'https://api.mupza.az';
export const WEBSITE_URL = 'https://mupza.com';
export const api_url = BASE_URL + '/api/v1/';
export const export_url = BASE_URL + '/storage/';
export const api_url_admin = BASE_URL + '/api/v1/dashboard/admin/';
export const api_url_admin_dashboard = BASE_URL + '/api/v1/dashboard/';
export const IMG_URL =
  'https://mupza-space.ams3.digitaloceanspaces.com/public/images/';
export const MAP_API_KEY = 'AIzaSyCcph5daZRNUdiB32KOI3JCwocY9B3I5F4';
export const VAPID_KEY =
  'BISZXkSyXb6mX6Rt86XWAtvR77fMqcy1zmi8vpQttiAnzePyQQs1Kf1yvJ9cqpA-yZ56jP65e1RtRj29klsS_lE';

export const API_KEY = 'AIzaSyAZhSB0gZjYdbWfN2q5JK855o3PYUQlm2k';
export const AUTH_DOMAIN = 'mupza-5dc8e.firebaseapp.com';
export const PROJECT_ID = 'mupza-5dc8e';
export const STORAGE_BUCKET = 'mupza-5dc8e.appspot.com';
export const MESSAGING_SENDER_ID = '616231997880';
export const APP_ID = '1:616231997880:web:c7646bcbab5aaac7223f4d';
export const MEASUREMENT_ID = 'G-2JHS53J1QQ';
