import request from './request';

const statisticService = {
  getAllCount: (params) =>
    request.get('dashboard/admin/statistics/count', {
      params,
      headers: {
        timeout: Infinity,
      },
    }),
  getAllSum: (params) =>
    request.get(`dashboard/admin/statistics/sum`, {
      params,
      headers: {
        timeout: Infinity,
      },
    }),
  topCustomers: (params) =>
    request.get(`dashboard/admin/statistics/customer/top`, {
      params,
      headers: {
        timeout: Infinity,
      },
    }),
  topProducts: (params) =>
    request.get(`dashboard/admin/statistics/products/top`, {
      params,
      headers: {
        timeout: Infinity,
      },
    }),
  orderSales: (params) =>
    request.get(`dashboard/admin/statistics/orders/sales`, {
      params,
      headers: {
        timeout: Infinity,
      },
    }),
  ordersCount: (params) =>
    request.get(`dashboard/admin/statistics/orders/count`, {
      params,
      headers: {
        timeout: Infinity,
      },
    }),
};

export default statisticService;
