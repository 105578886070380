import {
  AuditOutlined,
  BankOutlined,
  BorderlessTableOutlined,
  CarOutlined,
  CarryOutOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  FileDoneOutlined,
  IdcardOutlined,
  InsertRowAboveOutlined,
  RocketOutlined,
} from '@ant-design/icons';

export const allStatuses = [
  { id: '1', label: 'New', icon: <BorderlessTableOutlined /> },
  { id: '2', label: 'Ready', icon: <FileDoneOutlined /> },
  { id: '5', label: 'Declared in advance', icon: <CarryOutOutlined /> },
  { id: '7', label: 'External warehouse', icon: <AuditOutlined /> },
  { id: '8', label: 'On the way', icon: <CarOutlined /> },
  { id: '36', label: 'At customs', icon: <IdcardOutlined /> },
  { id: '9', label: 'Local warehouse', icon: <BankOutlined /> },
  { id: '10', label: 'Handed over', icon: <InsertRowAboveOutlined /> },
  { id: '11', label: 'In the courier', icon: <RocketOutlined /> },
  { id: '12', label: 'Delivered to customer', icon: <CheckOutlined /> },
  { id: '13', label: 'Canceled', icon: <CloseCircleOutlined /> },
];
